/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.form-control {
  font-size: 14px;

  &::placeholder {
    color: #939393;
  }
}

// similar with tailwindcss
.leading-none {
  line-height: 0;
}
